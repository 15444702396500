import type { TypographyOwnProps } from '@mui/material';
import { Typography } from '@mui/material';
import type { ReactNode } from 'react';

export type TypographyProps = {
  id?: string;
  children: ReactNode;
} & Partial<TypographyOwnProps>;

export function H1({ children, ...args }: TypographyProps) {
  return (
    <Typography {...args} variant="h1">
      {children}
    </Typography>
  );
}

export function H2({ children, ...args }: TypographyProps) {
  return (
    <Typography {...args} variant="h2">
      {children}
    </Typography>
  );
}

export function H3({ children, ...args }: TypographyProps) {
  return (
    <Typography {...args} variant="h3">
      {children}
    </Typography>
  );
}

export function H4({ children, ...args }: TypographyProps) {
  return (
    <Typography {...args} variant="h4">
      {children}
    </Typography>
  );
}

export function H5({ children, ...args }: TypographyProps) {
  return (
    <Typography {...args} variant="h5">
      {children}
    </Typography>
  );
}

export function H6({ children, ...args }: TypographyProps) {
  return (
    <Typography {...args} variant="h6">
      {children}
    </Typography>
  );
}

export function Body1({ children, ...args }: TypographyProps) {
  return (
    <Typography {...args} variant="body1">
      {children}
    </Typography>
  );
}

export function Body2({ children, ...args }: TypographyProps) {
  return (
    <Typography {...args} variant="body2">
      {children}
    </Typography>
  );
}

export function Span({ children, ...args }: TypographyProps) {
  return (
    <Typography {...args} component="span">
      {children}
    </Typography>
  );
}

export function Caption({ children, ...args }: TypographyProps) {
  return (
    <Typography {...args} variant="caption">
      {children}
    </Typography>
  );
}

export function Subtitle1({ children, ...args }: TypographyProps) {
  return (
    <Typography {...args} variant="subtitle1">
      {children}
    </Typography>
  );
}

export function Subtitle2({ children, ...args }: TypographyProps) {
  return (
    <Typography {...args} variant="subtitle2">
      {children}
    </Typography>
  );
}

export function Helper1({ children, ...args }: TypographyProps) {
  return (
    <Typography {...args} variant="helper1">
      {children}
    </Typography>
  );
}
